:root {
  --animation: 350ms;
  --animation-fast: 250ms;
}

/* custom noty */
.noty_theme__custom {
  padding: 10px;
  font-size: 14px;
  background: #7fda56;
}
.survey-noty_body.noty_body {
  text-align: center;
  font-weight: 400;
  border: none;
  background: #7fda56;
}

.game-time_machine {
  padding: 0.5rem 1rem;
  filter: invert(1);
  width: 58px;
}

.game_timer {
  top: 3.2rem;
}

.jiggle-row {
  animation: jiggle-row linear;
  animation-duration: var(--animation-fast);
}

.cell-fill-animation {
  animation: onTypeWord linear;
  animation-duration: var(--animation);
}

.cell-reveal {
  animation-duration: var(--animation);
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCell;
}

.cell-reveal.present {
  animation-name: revealPresentCell;
}

.cell-reveal > .letter-container {
  animation: letterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

@media screen and (min-width: 425px) {
  .game-time_machine {
    width: 68px;
  }
}
@media screen and (min-width: 556px) {
  .game_timer {
    top: 4rem;
  }
}

@keyframes jiggle-row {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes onTypeWord {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes revealAbsentCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealCorrectCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealPresentCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes letterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}
